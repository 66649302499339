import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`We build personalized apps for large and small businesses. Creating missing blocks to optimize the existing business processes, we create customized solutions to link workers and businesses, to simplify and speed up business processes, and to reduce costs by incorporating modern design concepts along with the latest cloud, mobile and desktop technologies.`}</p>
    </PageDescription>
    <h2>{`Enterprise software development`}</h2>
    <Row mdxType="Row">
      <Column colMd={5} colLg={12} className="bx--type-expressive-heading-03" mdxType="Column">
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/enterprise.svg",
            "alt": "Enterprise"
          }}></img>
        </div>
      </Column>
      <Column colMd={5} colLg={8} className="bx--type-expressive-heading-03" mdxType="Column">
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      